<div class="login-page" [ngClass]="{'initial-loading': showLogin}">
  <div class="login-loader">
    <div class="loader-inner">
        <div class="loader-image">
            <img src="assets/images/carrot-logo-black-cropped.png" alt="">
        </div>
        <div class="loader-progress"></div>
    </div>
  </div>
  <div class="login-widget" [ngClass]="{'loading': loaderShown}">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img [src]="competitionLogo" alt="">
        <div class="loader">
          <div class="loader-inner"></div>
        </div>
      </div>
      <div class="login-form-wrap" *ngIf="!hideLogin && !ssoAuthDisplayService.ssoGenericScreen.visible">
        <h1 class="title">Login</h1>
        <div class="action-failed" *ngIf="showErrorMessage">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="input-wrap">
          <label for="user-email" class="input-label">
            <input
              type="text"
              id="user-email"
              class="input-text"
              [(ngModel)]="userEmail"
              (keydown)="keyDownHandler($event)"
              maxlength="256"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{filled: userEmail}"
            >
              Email
            </span>
          </label>
          <label for="user-password" class="input-label">
            <input
              [type]="passwordInputType"
              #passwordInput="ngModel"
              id="user-password"
              class="input-text"
              [(ngModel)]="userPassword"
              (keydown)="keyDownHandler($event)"
              maxlength="256"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{filled: userPassword}"
            >
              Password
            </span>
            <span
              class="icon icon-right material-icons-outlined show-pass-button"
              (click)="showPasswordButtonClicked()"
              [ngClass]="{'hide-pass': passwordButtonTooltip === 'Hide password'}"
              [pTooltip]="passwordButtonTooltip"
            >
              visibility
            </span>
          </label>
        </div>
        <div class="extra-buttons-wrap">
          <a routerLink="/forgot-password" class="button-forgotten-pass">Forgot Password?</a>
        </div>
        <div class="button-wrap">
          <button
            class="button button-primary button-login"
            (click)="onSubmit()"
          >
            Login
          </button>
        </div>
        <div class="bottom-button-wrap" *ngIf="isRegistrationOpen">
          <span class="text">Don't have an account?</span>
          <a routerLink="/register" class="button-forgotten-pass">Register!</a>
        </div>
        <div class="bottom-back-wrap">
          <a [href]="feBaseUrl">Back to Homepage</a>
        </div>
        <!-- TODO - remove this when not needed -->
        <div class="button-wrap" *ngIf="ssoAuthService.currentRpUser$ | async as user">
          <p>{{user | json}}</p>
        </div>
      </div>
      <div class="login-form-wrap email-not-verified" *ngIf="loginWithNotVerifiedEmail && !ssoAuthDisplayService.ssoGenericScreen.visible">
        <h1 class="title">Email not verified</h1>
        <p class="subtitle">Looks like you haven't verified your email.</p>
        <p class="subtitle">If you don&acute;t see a verification email from us in your inbox, you may need to check your spam or junk folder.</p>
        <p class="subtitle extra-margin">Still can&acute;t find the email?</p>
        <div class="button-wrap">
          <button
            class="button button-primary button-login"
            (click)="resendVerificationEmail()"
          >
            Resend verification email
          </button>
        </div>
        <div class="bottom-button-wrap">
          <a (click)="resetLoginPage()" class="button-forgotten-pass">Back to Login</a>
        </div>
      </div>

      <app-auth-generic-screen></app-auth-generic-screen>
    </div>
  </div>
</div>
