import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  CardModule,
  ModalModule,
  MSelectModule,
  ScrollToTopModule,
  ScoreBarModule,
  SubmissionsDropdownModule,
  ChkModule,
  InputModule,
  DDModule
} from "./components";
import { DropdownModule } from "primeng/dropdown";
import { ClickOutsideModule } from "ng-click-outside";
import { RadioButtonComponent } from "./components/radio-button/radio-button.component";
import { ToggleComponent } from "./components/toggle/toggle.component";
import { FormioModule } from "@formio/angular";
import { TooltipModule } from "primeng/tooltip";
import { NotificationSmallComponent } from "./components/notification-small/notification-small.component";
import { NotificationMediumComponent } from "./components/notification-medium/notification-medium.component";
import { NotificationLargeComponent } from "./components/notification-large/notification-large.component";
import { SingleSelectComponent } from "./components/single-select/single-select.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { MultiSelectModule } from "primeng/multiselect";
import { PipesModule } from "./pipes/pipes.module";
import { DirectivesModule } from "./directives/directives.module";
import { TableModule } from "primeng/table";
import { MenuModule } from 'primeng/menu';
import { ExportDataModule } from "./components/export-data/export-data.module";
import { utcToCompTimePipe } from "./pipes/utc-to-comp-time.pipe";
import { DialogModule } from "primeng/dialog";
import { PaginatorModule } from "primeng/paginator";
import { MatStepperModule } from '@angular/material/stepper';
import { LayoutModule } from "@angular/cdk/layout";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { PasswordCheckDirective } from "./directives/PasswordCheckDirective";
import { formatLinksPipe } from "./pipes/format-links.pipe";
import { CarouselModule } from 'primeng/carousel';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    ModalModule,
    ClickOutsideModule,
    FormioModule,
    MSelectModule,
    ScrollToTopModule,
    ScoreBarModule,
    DropdownModule,
    TooltipModule,
    SubmissionsDropdownModule,
    RadioButtonModule,
    MultiSelectModule,
    ChkModule,
    InputModule,
    DDModule,
    PipesModule,
    DirectivesModule,
    TableModule,
    MenuModule,
    ExportDataModule,
    DialogModule,
    PaginatorModule,
    MatStepperModule,
    LayoutModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    CarouselModule,
    MatSnackBarModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    ModalModule,
    ClickOutsideModule,
    RadioButtonComponent,
    ToggleComponent,
    FormioModule,
    MSelectModule,
    ScoreBarModule,
    SubmissionsDropdownModule,
    ChkModule,
    DDModule,
    NotificationSmallComponent,
    NotificationMediumComponent,
    NotificationLargeComponent,
    SingleSelectComponent,
    InputModule,
    PipesModule,
    DirectivesModule,
    TableModule,
    MenuModule,
    ExportDataModule,
    DialogModule,
    PaginatorModule,
    MatStepperModule,
    LayoutModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    PasswordCheckDirective,
    CarouselModule,
    MatSnackBarModule,
  ],
  declarations: [
    RadioButtonComponent,
    ToggleComponent,
    NotificationSmallComponent,
    NotificationMediumComponent,
    NotificationLargeComponent,
    SingleSelectComponent,
    PasswordCheckDirective
  ],
  providers: [
    DatePipe,
    utcToCompTimePipe,
    formatLinksPipe,
  ]
})
export class SharedModule {}
