import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { NotificationDialogService } from '../services/notification-dialog.service';
import { StorageService } from '../services/storage.service';
import { FirebaseAuthWrapperService } from '../services/firebase-auth-wrapper.service';
import { PublicConfigService } from '../services/public.config.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderStateInterceptor implements HttpInterceptor {
  requestCount = 0;
  noLoaderRoutes = [
    'loader=false',
    'api/dataset/dropdown', //location control
    'formsubmission',
    'selfregister',
    'upsert-judgement-step'
  ]

  constructor(
    private router: Router,
    private storage: StorageService,
    private loaderService: LoaderService,
    private notificationDialogService: NotificationDialogService,
    private firebaseAuthWrapperService: FirebaseAuthWrapperService,
    private publicConfigService: PublicConfigService
  ) {}


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next))
  }

  async handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ) {

    this.requestCount++;

    if (this.noLoaderRoutes.filter(str => req.url.includes(str)).length === 0) {
      this.loaderService.showLoader();
    } else if (req.url.includes('formsubmission/submit')) {
      // show loading state on form submission specifically
      this.loaderService.showLoader();
    }

    const ssoJwt = await this.firebaseAuthWrapperService.getJwt();

    if(ssoJwt) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${ssoJwt}`),
      });
    }


    return await next.handle(req).pipe(
      tap((response: any) => {
        // this will be executed for all successfull requests that have body object - our own responses have this
        if (response?.body) {

          // getting the body of successfull response - needed, because we throw errors in it
          const responseBody = response.body;

          // checking if response has succeeded - this will be true for all successfull calls and false for calls that return OUR OWN error
          if (responseBody.success === false) {

            // why are the errors an array? TBD with @Bart
            // getting errors from the response body and assigning them to different vars for easier understanding
            const errors = responseBody.errors[0];
            const errorClientMessage = errors.clientMesssage;
            const errorDevMessage = errors.error;
            const errorCode = errors.rpErrorCode;
            const responseURL = response.url;

            // composing the final client message
            const finalErrorMessage = errorClientMessage;

            // calling notification service and passing the needed information
            // TODO - to be updated with the correct error statements
            this.notificationDialogService.confirm(`An error occurred on ${responseURL}`, errorCode, finalErrorMessage, 'Ok', '400px')
            .then((confirmed) => {
              // if confirmed === true - user confirmed, if false - user clicked cancel button
              if (confirmed) {
                // console.log('confirm leave');
              } else {
                // console.log('do not confirm leave');
              };
            })
            .catch(() => {
              // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
            });
          };
        };
      }),
      catchError((err) => {
        // this will be executed for all responses that didn't pass for some reason
        // this will return HttpsErrorResponse

        if(err.url.startsWith(this.publicConfigService.getCarrotSSOConfig().ssoBaseUrl)) {
          this.handleSsoApiError(err);
          return throwError(err);
        }

        // redirect to 403 on BE_API_S_AccessToStageDenied, BE_API_J_AccessDenied
        if (err.status === 403 && 
          err.error?.errors?.find(e => ['BE_API_S_AccessToStageDenied', 'BE_API_J_AccessDenied'].includes(e.rpErrorCode))) {
            this.router.navigate(['/403']);
            return throwError(err);
        }

        // compiling the error status code and error message
        let APIErrorStatus = '';
        let APIErrorsArray = [];
        let APIErrorMessage = '';
        if (err.status) {
          APIErrorStatus = err.status;
        };
        if (err.error?.errors) {
          APIErrorsArray = err.error.errors;
          APIErrorsArray.forEach(element => {
            APIErrorMessage = `${element.clientMessage}`;
          });
        } else {
          let title;
          if (err.error?.title) {
            title = err.error.title
            APIErrorMessage = title;
          };

          let traceId;
          if (err.error?.traceId) {
            traceId = err.error.traceId;
            APIErrorMessage = `Trace ID: ${traceId}`;
          };

          if (title && traceId) {
            APIErrorMessage = `${title}. Trace ID: ${traceId}`;
          };
        };

        if (err.status === 500) {
          APIErrorStatus = '';
          APIErrorMessage = `An unknown error occurred. Please try again or contact {supportEmailAddress}.`;
        }

        // calling notification service and passing the needed information
        // TODO - to be updated with the correct error statements
        this.notificationDialogService.confirm('An error occurred', APIErrorStatus, APIErrorMessage, 'Ok', '400px')
        .then((confirmed) => {
          // if confirmed === true - user confirmed, if false - user clicked cancel button
          if (confirmed) {
            // console.log('confirm leave');
          } else {
            // console.log('do not confirm leave');
          };
        })
        .catch(() => {
          // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
        });
        return throwError(err);
      }),
      finalize(() => {
        // finalizing the requests
        this.requestCount--;
        if (this.requestCount === 0) this.loaderService.hideLoader();
      })
    )
    .toPromise();
  }

  handleSsoApiError(errorResponse) {
    let errorCode = "";
    let message = "";
    let handleError: boolean = true;

    errorResponse.error.errors.forEach((error, index) => {
        switch (error.errorCode) {
            case 400100:
              handleError = false;
              break;
            // case 400900:
            //     title = "Email not verified";
            //     message += error.message;
            //     showErrorInGenericScreen = true;
            //     activeLinks = [
            //         "Login", "ForgotPassword"
            //     ]
            //     break;
            case 400130:
              handleError = false;
              break;
            // case 400140:
            //     showError = false;
            //     break;

            // verification token expired
            case 400170:
            // verification token not found
            case 404101:
              handleError = false;
              break;

            // // PasswordResetRequestExpired
            // case 400180:
            //     title = "Password Reset Failed";
            //     message += "The link has expired or is invalid. Please proceed to “Forgot Password” to receive a new password reset link.";
            //     showErrorInGenericScreen = true;
            //     activeLinks = [
            //         "Login", "ForgotPassword"
            //     ]
            //     break;

            // PasswordResetRequestNotFound
            case 404102:
              handleError = false;
              break;
            default:
              message += error.message;
              errorCode = error.code;
              break;
        }
    })

    if (handleError) {
        // display error in modal
        this.notificationDialogService.confirm('An error occurred', errorCode, message, 'Ok', '400px')
    }
  }
}
