import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { CommonService } from "./shared/services/common.service";
import { LoaderService } from "./shared/services/loader.service";
import { StorageService } from "./shared/services/storage.service";
import { Location } from "@angular/common";
import { GradientConfig } from "./app-config";
import { SsoAuthService } from "./shared/services/sso-auth.service";
import { filter } from "rxjs/operators";
import { CompetitionInformation } from "./shared/model/common.model";
import { PublicConfigService } from "./shared/services/public.config.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as CookieConsent from "vanilla-cookieconsent";
import { StickyHeaderService } from "./shared/services/sticky-header.service";
import { CookieConsentConfigService } from "./shared/services/cookie-consent-config.service";

interface componentHeaderRef {
  available?: boolean;
  elementRef?: ElementRef;
}

// we need this for GTM
declare function gtag(...args: any): void;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  public loader = false;
  windowScrolled: boolean = false;
  public hideNav = true;
  public loginPageVisible = false;
  public pageWrapperStopLoader = false;

  @ViewChild("customNavbarOuterWrapper") customNavbarOuterWrapper: ElementRef;
  public gradientConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  public isDashboardActive: boolean = false;

  public loginPageBackgroundImage: string = '';
  public competitionLogo: string = '';
  public competitionName: string = '';
  public currentYear: number = new Date().getFullYear();
  public compiledHeaderTitle: string = '';

  impersonationBannerShown: boolean = false;

  private componentHeaderRef: componentHeaderRef = {
    available: false,
    elementRef: undefined,
  };

  constructor(
    private router: Router,
    public loaderService: LoaderService,
    private commonService: CommonService,
    private storage: StorageService,
    private location: Location,
    public ssoAuthService: SsoAuthService,
    public publicConfig: PublicConfigService,
    public googleTagManagerService: GoogleTagManagerService,
    private stickyHeaderService: StickyHeaderService,
    private cookieConsentConfigService: CookieConsentConfigService
  ) {
    this.gradientConfig = GradientConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location["_baseHref"];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (
      currentURL === baseHerf + "/layout/collapse-menu" ||
      currentURL === baseHerf + "/layout/box" ||
      (this.windowWidth >= 992 && this.windowWidth <= 1024)
    ) {
      this.gradientConfig.collapseMenu = true;
    }

    this.navCollapsed = this.windowWidth >= 992 ? this.gradientConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  ngAfterViewInit(): void {
    const cookieConsentConfig = this.cookieConsentConfigService.getConfig();
    CookieConsent.run({
      ...cookieConsentConfig,
      onChange: async ({ cookie, changedCategories, changedServices }) => {
        gtag("consent", "update", {
          analytics_storage: cookie.categories.includes("analytics")
            ? "granted"
            : "denied",
          ad_storage: cookie.categories.includes("marketing")
            ? "granted"
            : "denied",
        });
        await this.cookieConsentConfigService.saveConsent(cookie.categories, 'change', this.publicConfig.getCookieInfoStorageUrl());
      },
      onFirstConsent: async ({ cookie }) => {
        await this.cookieConsentConfigService.saveConsent(cookie.categories, 'accept', this.publicConfig.getCookieInfoStorageUrl());
      },
      onConsent: async ({ cookie }) => {
        gtag("consent", "update", {
          analytics_storage: cookie.categories.includes("analytics")
            ? "granted"
            : "denied",
          ad_storage: cookie.categories.includes("marketing")
            ? "granted"
            : "denied",
        });
        await this.googleTagManagerService.addGtmToDom();
      },
      onModalShow: ({ modalName }) => {
        if (modalName === "preferencesModal") {
          document.querySelector("html").classList.add("modal-opened");
        }
      },
      onModalHide: ({ modalName }) => {
        if (modalName === "preferencesModal") {
          document.querySelector("html").classList.remove("modal-opened");
        }
      },
    });
  }
  async ngOnInit() {
    const rawQuery = this.location.path(false);
    this.ssoAuthService.setQueryStringParams(rawQuery);
    this.ssoAuthService.setReturnUrl(this.location.path());

    const competitionLogo = this.storage.retrieve(CompetitionInformation.brandingsLogo);
    if (competitionLogo !== null) {
      this.competitionLogo = `${competitionLogo}`;
    };
    const competitionName = this.storage.retrieve(CompetitionInformation.competitionName);
    if (competitionName !== null) {
      this.competitionName = `${competitionName}`;
    };

    this.ssoAuthService.impersonationInfo$.subscribe(impInfo => {
      if (impInfo) {
        this.impersonationBannerShown = true;
      }
    });
    this.router.events.subscribe((evt) => {
      if (evt instanceof ActivationStart) {
        this.hideNav = evt.snapshot.data.hideNav === undefined ? false : true;
        this.pageWrapperStopLoader = evt.snapshot.data.pageWrapperStopLoader === undefined ? false : true;
        this.loginPageVisible = evt.snapshot.data.loginPageVisible === undefined ? false : true;

        if (this.loginPageVisible) {
          this.commonService.competitionInfo
            .pipe(filter((res) => !!res))
            .subscribe(() => {
              const competitionLoginPageFromStorage = this.storage.retrieve(CompetitionInformation.brandingsLoginPageBackgroundImage);
              if (competitionLoginPageFromStorage !== null) {
                this.loginPageBackgroundImage = `url("${competitionLoginPageFromStorage}")`;
              };
            });
        }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // used for hiding the background shape on the dashboard screen
      if (evt instanceof NavigationEnd) {
        // removing the previous component header ref only on navigation end;
        this.stickyHeaderService.removeComponentHeaderRef();
        if (
          evt.urlAfterRedirects === "/dashboard" ||
          evt.urlAfterRedirects === "/dashboard-edit"
        ) {
          this.isDashboardActive = true;
        } else {
          this.isDashboardActive = false;
        }
      }

      if (!this.loginPageVisible) {
        // scroll to top when navigating to a new page
        // on all pages without the login pages
        window.scrollTo(0, 0);
      };
    });

    this.stickyHeaderService.getComponentHeaderAsObs().subscribe(componentHeaderRef => {
      this.componentHeaderRef = componentHeaderRef;
    });

    await this.getCompetitionInformation();
  }

  onScrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll($event: Event) {
    this.scroll($event);
  }

  async getCompetitionInformation() {
    const favicon = this.storage.retrieve("competitionFavicon");
    if (favicon) {
      document.querySelector('link[rel="icon"]').setAttribute("href", favicon);
    };
    const response: any = await this.commonService.GetCompetitionInformation().toPromise();
    if (response.data.brandingsFavicon && !favicon) {
      document.querySelector('link[rel="icon"]').setAttribute("href", response.data.brandingsFavicon);
    }
  }

  scroll = (event): void => {
    this.windowScrolled = window.pageYOffset >= 2000;
    let currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // if the page is scrolled and component header is not available, add solo style class to header
    if (currentScrollPosition > 0 && !this.componentHeaderRef.available) {
      this.customNavbarOuterWrapper?.nativeElement?.classList.add('scrolled-solo');
    } else {
      this.customNavbarOuterWrapper?.nativeElement?.classList.remove('scrolled-solo');
    }

    // if component header is available
    if (this.componentHeaderRef.available) {
      if (currentScrollPosition > 1) {
        // sticky component header
        if (!this.componentHeaderRef.elementRef.nativeElement.classList.contains("on-top")) {
          this.componentHeaderRef.elementRef.nativeElement.classList.add("on-top");
          this.componentHeaderRef.elementRef.nativeElement.classList.add('on-top-with-header');
        }
      } else {
        // non sticky component header
        this.componentHeaderRef.elementRef.nativeElement.classList.remove("on-top");
        this.componentHeaderRef.elementRef.nativeElement.classList.remove("on-top-with-header");
      }
    }
  };

  customNavbarOuterWrapperOnTop() {
    if (!this.customNavbarOuterWrapper.nativeElement.classList.contains("on-top")) {
      this.customNavbarOuterWrapper.nativeElement.classList.add("on-top");
    }
  }

  stopImpersonate() {
    this.ssoAuthService.stopImpersonate();
    this.impersonationBannerShown = false;
  }
}
