export const environment = {
  production: true,
  publicConfigUrl: '/api/public/configuration',
  localDevSsoRedirect: false,
  ssoLoginRedirectIntervalInHours: 24,

  sentryDisabledOnUrls: new RegExp('^(?!.*(?:rpqa20-gi.rampitplatform.com|rpqa20-postman.rampitplatform.com|localhost)).*$'),
  // sentry config
  sentry: {
    DSN: 'https://6761d6ef50034f2fafa4de0c36d6d1ff@o4504165036457984.ingest.us.sentry.io/4505527883399168',
    // environment setting needs to be updated as per the environment this is deployed to
    environment: 'qa',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  }
};
