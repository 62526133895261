<div class="fullscreen">
  <div class="container-stepper">
    <div class="header">
      <div class="heading">
        <h2>Reviewing: {{ participantName }}</h2>
      </div>
      <div class="actions">
        <div
          class="saved-date"
          [matTooltipDisabled]="saving"
          matTooltip="Every change was saved, the last change was on {{ lastUpdated | utcToCompTime: 'fullDateTime' }}"
        >
          {{ saving ? 'Saving' : 'Saved'}}
        </div>
        <app-dropdown [items]="formsArray" type="link" placeholderText="Submissions" optionLabel="name"></app-dropdown>
        <button
          class="button button-primary"
          [ngClass]="{'disabled-soft': hasUnsavedData}"
          (click)="saveAndExit()"
          [disabled]="hasUnsavedData"
          [tooltipDisabled]="!hasUnsavedData"
          pTooltip="Autosave is running"
          tooltipPosition="top"
        >
          Save & Exit
        </button>
      </div>
    </div>

    <div class="content">
      <mat-stepper
        [linear]="linear"
        [selectedIndex]="selectedStep"
        (selectionChange)="selectionChanged($event)"
        #stepper
        labelPosition="bottom"
        [orientation]="stepperOrientation"
        class="custom-stepper"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>
        <ng-container *ngFor="let step of steps; let first = first; let last = last;">
          <mat-step [completed]="step.completed" [aria-labelledby]="step.completed ? 'completed-step': 'not-completed-step'">
            <ng-template matStepLabel>
              <div
                class="custom-stepper-label"
                matTooltip="{{ step.title }} {{ step.rangeChanged || step.completed ? step.score : '' }}"
              >
                {{ step.title }} {{ step.rangeChanged || step.completed ? step.score : '' }}
              </div>
            </ng-template>
            <ng-template matStepContent>
              <h5 class="step-title">{{ step.title }}</h5>
              <p class="step-description" [innerHTML]="step.description | formatLinks"></p>
              <div [ngSwitch]="step.type">
                <div *ngSwitchCase="'formsreview'" class="step-container step-form">
                  <a (click)="form.isRead = true; formsClicked(step, form.formStageId)" *ngFor="let form of step.forms" [href]="getFormUrl(form)" target="_blank">
                    <mat-icon fontSet="material-icons-round" [class.checked]="form.isRead">{{ form.isRead ? 'check_circle' : 'outbound' }}</mat-icon>
                    <span>{{ form.title }}</span>
                  </a>
                </div>
                <div *ngSwitchCase="'trait'" class="step-container step-slider">
                  <div class="range-definition">
                    <div class="range-definition-inner">
                      <div [innerHTML]="step.rangeDefinition | formatLinks"></div>
                    </div>
                  </div>
                  <div class="slider-container">
                    <div class="anchor-low">{{ step.leftAnchor }}</div>
                    <div class="slider-wrapper slider-trait">
                      <mat-slider
                        min="0"
                        max="5"
                        step="0.1"
                        [(ngModel)]="step.score"
                        (ngModelChange)="sliderModelChange(step)"
                        [thumbLabel]="step.rangeChanged"
                        [displayWith]="sliderDisplay(step)"
                        tickInterval="10"
                        (input)="sliderChange(step, $event.value)"
                      ></mat-slider>
                      <div class="slider-ticks">
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">0</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">1</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">2</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">3</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">4</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">5</span>
                        </span>
                      </div>
                    </div>
                    <div class="anchor-high">{{ step.rightAnchor }}</div>
                  </div>
                  <mat-form-field appearance="outline" class="feedback-field">
                    <mat-label>Feedback</mat-label>
                    <textarea #feedbackTextarea matInput [(ngModel)]="step.comment" (ngModelChange)="feedbackChange(step.comment, step)"></textarea>
                    <mat-hint align="end">Words Count: {{ countFeedbackWords(feedbackTextarea.value) || 0 }} (Minimum of {{ wordCount }} Words)</mat-hint>
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'overallimpression'" class="step-container step-slider">
                  <div class="slider-container">
                    <div class="slider-wrapper slider-overall-impression">
                      <mat-slider
                        min="0"
                        max="100"
                        step="1"
                        [(ngModel)]="step.score"
                        (ngModelChange)="sliderModelChange(step)"
                        [thumbLabel]="step.rangeChanged"
                        [displayWith]="sliderDisplay(step)"
                        tickInterval="20"
                        (input)="sliderChange(step, $event.value)"
                      ></mat-slider>
                      <div class="slider-ticks">
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">0</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">20</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">40</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">60</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">80</span>
                        </span>
                        <span class="slider-tick">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">100</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'submit'" class="step-container step-summary">
                  <table class="summary-table">
                    <thead>
                      <tr>
                        <th>Criteria</th>
                        <th>Score</th>
                        <th>Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sum of step.summary; let first = first; let last = last; let i = index">
                        <td>
                          <a *ngIf="!last" (click)="goToStep(sum.step)">{{ sum.criteria }}</a>
                          <span *ngIf="last">{{ sum.criteria }}</span>
                        </td>
                        <td>{{ sum.score }}</td>
                        <td>
                          <div class="summary-feedback-wrap" [ngClass]="{'expanded': sum.expanded === true}">
                            <span class="expand-trigger" *ngIf="sum.showFeedbackOverlay" (click)="expandFeedback(sum, i)">
                              <span class="icon material-icons">expand_more</span>
                            </span>
                            <div class="summary-feedback" [ngClass]="{'behind-overlay': sum.showFeedbackOverlay === true}">
                              <p>{{ sum.feedback }}</p>
                            </div>
                            <div class="summary-feedback-overlay" *ngIf="sum.showFeedbackOverlay">
                              <p>{{ sum.feedback }}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="step-errors" *ngIf="step?.errors?.length > 0">
                <ul>
                  <li *ngFor="let error of step.errors">
                    {{ error.text }}
                  </li>
                </ul>
              </div>
              <div class="custom-stepper-buttons">
                <button class="button button-secondary" (click)="previous()" *ngIf="!first">Back</button>
                <button
                  class="button button-primary"
                  [ngClass]="{'disabled-soft': hasUnsavedData}"
                  (click)="next(step)"
                  *ngIf="!last"
                  [disabled]="hasUnsavedData"
                  [tooltipDisabled]="!hasUnsavedData"
                  pTooltip="Autosave is running"
                  tooltipPosition="top"
                >
                  Next
                </button>
                <button class="button button-primary" (click)="saveAndExit()" *ngIf="last">Save & Exit</button>
              </div>
            </ng-template>
          </mat-step>
        </ng-container>
      </mat-stepper>
    </div>
  </div>
</div>
