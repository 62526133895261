import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { PUBLIC_CONFIGURATION_TOKEN } from './app/shared/services/public.config.service';
import { environment } from './environments/environment';
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from "@sentry/angular-ivy";

// enable sentry only on domains that don't match regex (negative lookahead)
if (environment.sentryDisabledOnUrls.test(window.location.hostname)) {
  Sentry.init({
    dsn: environment.sentry.DSN,
    environment: environment.sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({}),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        mutationLimit: 2000,
      }),
    ],
    enableTracing: false,
    // Session Replay
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    maxValueLength: 3000,
  });
}

fetch(environment.publicConfigUrl)
  .then((res) => res.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: PUBLIC_CONFIGURATION_TOKEN, useValue: config.data }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });